import { Routes } from '@angular/router';
import { PageNotFoundComponent } from 'src/app/modules/core/components/page-not-found/page-not-found.component';
var ɵ0 = function () { return import("./modules/frontend/frontend.module.ngfactory").then(function (m) { return m.FrontendModuleNgFactory; }); }, ɵ1 = function () { return import("./modules/backend/backend.module.ngfactory").then(function (m) { return m.BackendModuleNgFactory; }); };
var appRoutes = [
    {
        path: '',
        loadChildren: ɵ0
    },
    {
        path: 'admin',
        loadChildren: ɵ1
    },
    { path: 'pageNotFound', component: PageNotFoundComponent },
    { path: '**', component: PageNotFoundComponent }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1 };
