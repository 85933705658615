import { OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var PreviousRouteService = /** @class */ (function () {
    function PreviousRouteService(router) {
        this.router = router;
    }
    PreviousRouteService.prototype.getPreviousUrl = function () {
        return this.previousUrl;
    };
    PreviousRouteService.prototype.ngOnInit = function () {
        var _this = this;
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                _this.currentUrl = _this.router.url;
            }
            if (event instanceof NavigationEnd) {
                _this.previousUrl = _this.currentUrl;
                _this.currentUrl = event.url;
            }
        });
    };
    PreviousRouteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PreviousRouteService_Factory() { return new PreviousRouteService(i0.ɵɵinject(i1.Router)); }, token: PreviousRouteService, providedIn: "root" });
    return PreviousRouteService;
}());
export { PreviousRouteService };
