import * as tslib_1 from "tslib";
import { OnInit, Renderer2 } from '@angular/core';
import { BasePageComponent } from './modules/core/components/bases/base-page-component';
var AppComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AppComponent, _super);
    function AppComponent(spinnerService, renderer) {
        var _this = _super.call(this) || this;
        _this.spinnerService = spinnerService;
        _this.renderer = renderer;
        _this.accessStorage = true;
        return _this;
    }
    AppComponent.prototype.ngOnInit = function () {
        try {
            if (localStorage.getItem) {
                console.log('allow access storage');
            }
            else {
                this.accessStorage = false;
                console.log('not allow access storage');
            }
        }
        catch (_a) {
            this.accessStorage = false;
            console.log('not allow access storage');
        }
        this.spinnerService.setupShowOnBodyUntil(this.renderer, this.onDestroy$);
    };
    return AppComponent;
}(BasePageComponent));
export { AppComponent };
