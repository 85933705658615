import * as tslib_1 from "tslib";
import { BaseComponent } from 'src/app/modules/core/components/bases/base-component';
var BasePageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BasePageComponent, _super);
    function BasePageComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return BasePageComponent;
}(BaseComponent));
export { BasePageComponent };
