import { Renderer2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import * as i0 from "@angular/core";
//these must be declared here
//the interceptor service uses a different instance
//than the version injected into components so these must be declared globally
var _spinnerShown$ = new BehaviorSubject(false);
var shownCount = 0;
var SpinnerService = /** @class */ (function () {
    function SpinnerService() {
    }
    SpinnerService.prototype.showUntilFinished = function (observable) {
        var _this = this;
        this.show();
        return observable.pipe(finalize(function () { return _this.hide(); }));
    };
    Object.defineProperty(SpinnerService.prototype, "spinnerShown$", {
        get: function () {
            return _spinnerShown$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    SpinnerService.prototype.show = function () {
        shownCount++;
        if (shownCount == 1)
            _spinnerShown$.next(true);
    };
    SpinnerService.prototype.hide = function () {
        shownCount--;
        if (shownCount <= 0) {
            _spinnerShown$.next(false);
            shownCount = 0;
        }
    };
    SpinnerService.prototype.setupShowOnBodyUntil = function (renderer, onDestroy) {
        this.spinnerShown$
            .pipe(takeUntil(onDestroy))
            .subscribe(function (showSpinner) {
            if (showSpinner) {
                renderer.addClass(document.body, 'spinner');
            }
            else {
                renderer.removeClass(document.body, 'spinner');
            }
        });
    };
    SpinnerService.prototype.intercept = function (req, next) {
        var requestPipeline = next.handle(req);
        return this.showUntilFinished(requestPipeline);
    };
    SpinnerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SpinnerService_Factory() { return new SpinnerService(); }, token: SpinnerService, providedIn: "root" });
    return SpinnerService;
}());
export { SpinnerService };
