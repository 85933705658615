import * as tslib_1 from "tslib";
import { RouteReuseStrategy } from '@angular/router';
var AppRouteStrategy = /** @class */ (function (_super) {
    tslib_1.__extends(AppRouteStrategy, _super);
    function AppRouteStrategy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AppRouteStrategy.prototype.retrieve = function (route) {
        return null;
    };
    AppRouteStrategy.prototype.shouldAttach = function (route) {
        return false;
    };
    AppRouteStrategy.prototype.shouldDetach = function (route) {
        return false;
    };
    AppRouteStrategy.prototype.shouldReuseRoute = function (future, curr) {
        return future.routeConfig === curr.routeConfig || future.data.reusePath === curr.routeConfig.path;
    };
    AppRouteStrategy.prototype.store = function (route, handle) {
    };
    return AppRouteStrategy;
}(RouteReuseStrategy));
export { AppRouteStrategy };
