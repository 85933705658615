import * as tslib_1 from "tslib";
import { OnInit, Renderer2 } from '@angular/core';
import { BasePageComponent } from '../bases/base-page-component';
var PageNotFoundComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageNotFoundComponent, _super);
    function PageNotFoundComponent(spinnerService, renderer) {
        var _this = _super.call(this) || this;
        _this.spinnerService = spinnerService;
        _this.renderer = renderer;
        _this.title = 'Page Not Found';
        return _this;
    }
    PageNotFoundComponent.prototype.ngOnInit = function () {
        this.spinnerService.setupShowOnBodyUntil(this.renderer, this.onDestroy$);
    };
    return PageNotFoundComponent;
}(BasePageComponent));
export { PageNotFoundComponent };
